
export const useAnimations = () => {

    const handleClickGoHome = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoServices = () => {
        const element = document.getElementById('services');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoContact = () => {
        const element = document.getElementById('contact');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return {
        handleClickGoHome,
        handleClickGoServices,
        handleClickGoContact,

    }

}