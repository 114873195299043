import styled from "styled-components"

const Loader = styled.div`

    @keyframes rotation_51512 {
        70% {
            box-shadow: 0px 0px 10px 50px rgba(255, 15, 191, 0.526);
        }

        90% {
            box-shadow: 0px 0px 10px 50px rgba(241, 57, 5s7, 0.04);
        }

        100% {
            opacity: 0.5;
            transform: rotate(360deg);
        }
    }

    width: 220px;
    height: 220px;
    border: solid 4px rgba(214, 38, 223, 0);
    border-top: solid 5px rgb(214, 38, 223);
    border-radius: 50%;
    transition: all 0.5s;
    animation: rotation_51512 1.5s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
`

const Loader02 = styled.div`

`


export const Loader06 = () => {

    return (

        <>
            <Loader />
            <Loader02 />
        </>

    )
}

export default Loader06;