import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { globalTexts } from "../constants/TextsConstants"

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 60px;

    @media ${device.sm} {
        margin-top: 40px;
    }
`

const IconButtonsContainer = styled.div`
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media ${device.md} {
        width: 300px;
    }

    @media ${device.sm} {
        width: 250px;
    }

    @media ${device.xs} {
        width: 200px;
    }
`

const SocialButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
        background-color: rgba(255,255,255,0.2);

        img {
            transform: scale(0.7);
        }
    }
`

const IconButton = styled.img`
    width: 40px;
    height: 40px;
    transition: all 0.3s ease-in-out;

    @media ${device.md} {
        width: 35px;
        height: 35px;
    }

    @media ${device.sm} {
        width: 30px;
        height: 30px;
    }

    @media ${device.xs} {
        width: 25px;
        height: 25px;
    }
`

export const Social = () => {

    const { handleLogEvent } = useLogEvent();

    return (

        <Container>

            <IconButtonsContainer>

                {
                    globalTexts.social.map((value) => (

                        <SocialButton
                            key={value.event}
                            className="utils__fade-in-fast-center"
                            onClick={(e) => {

                                e.preventDefault();

                                window.open(value.link, "_blank");

                                handleLogEvent(value.event)
                            }}
                        >

                            {
                                value.image ? (

                                    <IconButton loading="lazy" src={value.image} alt={value.event} />

                                ) : <></>
                            }


                        </SocialButton>

                    ))
                }

            </IconButtonsContainer>

        </Container>

    )
}

export default Social;