import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts } from "../constants/TextsConstants"

const Container = styled.div`
    width: 100%;
    padding: 8vh 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
    z-index: 2;

    @media ${device.md} {
        padding: 5vh 10% !important;
    }   

`

const Title = styled.h2`
    width: 100%;
    font-weight: 500;
    font-size: 54px;
    color: #fff;
    letter-spacing: 3px;
    line-height: 60px;
    margin-bottom: 70px;
    text-transform: uppercase;

    @media ${device.xl} {
        font-size: 46px;
    }

    @media ${device.lg} {
        font-size: 30px;
        line-height: 50px;
    }

    @media ${device.md} {
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 30px;
    }  

    @media ${device.sm} {
        width: 110%;
        font-size: 19px;
        line-height: 30px;
    }

    @media ${device.xs} {
        font-size: 14px;
        line-height: 25px;
    }
`

const DescriptionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 50px;
    place-items: center;

    @media ${device.md} {
        gap: 30px;
    }

    @media ${device.sm} {
        gap: 20px;
    }
`

const Description = styled.p`
    width: 80%;
    height: 100%;
    padding: 20px 50px;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 7px;
    background-color: rgba(150, 150, 150, 0.1);
    border: 2px solid #FF18D3;
    box-shadow: 0px 0px 12px 0px rgba(255, 24, 211, 0.9);

    @media ${device.lg} {
        font-size: 14px;
    }
    
    @media ${device.md} {
        width: 90%;
    }

    @media ${device.sm} {
        font-size: 12px;
        box-shadow: 0px 0px 8px 0px rgba(255, 24, 211, 0.9);
        padding: 15px 25px;
        width: 95%;
        
    }

    @media ${device.xs} {
        font-size: 11px;
        padding: 15px 20px;
        width: 100%;
    }
`

export const WhatIncludes = () => {

    return (


        <Container>


            <Title className="utils__fade-in-fast-left">{globalTexts.includes.title}</Title>

            <DescriptionContainer>

                {
                    globalTexts.includes.data.map((value) => (
                        <Description className="utils__fade-in-fast-top" key={value}>
                            {value}
                        </Description>
                    ))
                }

            </DescriptionContainer>

        </Container>

    )
}

export default WhatIncludes;