import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts } from "../constants/TextsConstants"

const Container = styled.div`
    width: 100%;
    padding: 8vh 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
    z-index: 1;

    @media ${device.md} {
        padding: 5vh 10% !important;
    }   

`

const DescriptionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 55px 60px;
    place-items: center;

    @media ${device.lg} {
        gap: 50px;
    }
    
    @media ${device.md} {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        
        & > p {
            grid-column-start: unset !important;
            grid-column-end: unset !important;
        }
    }
    
    @media ${device.sm} {
        gap: 30px;
    }

    &> p:nth-of-type(1) {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    &> p:nth-of-type(2) {
        grid-column-start: 3;
        grid-column-end: 5;
}

    &> p:nth-of-type(3) {
        grid-column-start: 2;
        grid-column-end: 4;
    }

    
`

const Description = styled.p`
    width: 100%;
    height: 100%;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff;
    background-color: rgba(255, 24, 211, 0.5);
    letter-spacing: 1px;
    font-weight: 300;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #FF18D3;
    box-shadow: 0px 0px 12px 0px rgba(255, 24, 211, 0.9);

    @media ${device.lg} {
        font-size: 14px;
    }

    @media ${device.md} {
        width: 95%;
    }

    @media ${device.sm} {
        font-size: 12px;
        box-shadow: 0px 0px 8px 0px rgba(255, 24, 211, 0.9);
        padding: 15px 25px;
    }

    @media ${device.xs} {
        font-size: 11px;
        padding: 15px 20px;
    }
`

export const Fyi = () => {

    return (


        <Container>

            <DescriptionContainer>

                {
                    globalTexts.fyi.map((value) => (
                        <Description className="utils__fade-in-fast-center" key={value}>
                            {value}
                        </Description>
                    ))
                }

            </DescriptionContainer>

        </Container>

    )
}

export default Fyi;