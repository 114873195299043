import { styled } from "styled-components";
import { colors, fonts } from "../../styles/stylesConfig";
import { CSSProperties } from "react";

interface Props {
    size: string;
    color?: string;
    bold?: boolean;
    family?: string;
    children: any;
    className?: string;
    style?: CSSProperties;
}

const Text = styled.h1<{ styles: Props }>`
    font-size: ${(props) => props.styles.size};
    color: ${(props) => props.styles.color ?? colors.primary_dark};
    font-weight: ${(props) => props.styles.bold ? 600 : 'normal'};
    font-family: ${(props) => props.styles.family ?? fonts.main};

    & > span {
        color: ${colors.grey_dark};
        font-weight: normal;
    }
`

export const Typography = (styles: Props) => {

    return (

        <Text className={styles.className} styles={styles} style={styles.style}>

            {styles.children}

        </Text>

    )
}