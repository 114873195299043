
export const types = {
    
    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',
    
    uiStartLoadingAsync: '[UI] Start loading Async',
    uiFinishLoadingAsync: '[UI] Finish loading Async',
    
    uiSetPrimaryColor: '[UI] Set Primary Color',
}