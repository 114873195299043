import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts } from "../constants/TextsConstants"

const Container = styled.div`
    width: 100%;
    height: fit-content;
    padding: 5vh 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
    z-index: 1;

    @media ${device.md} {
        padding: 8vh 10% !important;
    }   

`

const InfoContainer = styled.div`
    width: 90%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 100px;
    place-items: center;
    
    &> p {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    &> img {
        grid-column-start: 4;
        grid-column-end: 5;
    }

    &> div {
        grid-column-start: 4;
        grid-column-end: 5;
        }
    @media ${device.lg} {
        gap: 0px;

    }
    
    @media ${device.md} {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        width: 100%;

        &> p {
        grid-column-start: unset;
        grid-column-end: unset;
    }

        &> img {
            grid-column-start: unset;
            grid-column-end: unset;
        }

        &> div {
            grid-column-start: unset;
            grid-column-end: unset;
        }
    }
    
    @media ${device.sm} {
        
    }


    
`

const Info = styled.p`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 400;

    @media ${device.lg} {
        font-size: 16px;
    }

    @media ${device.md} {
        text-align: center;
    }

    @media ${device.sm} {
        font-size: 14px;
    }

    @media ${device.xs} {
        font-size: 12px;
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    min-width: 200px;
    object-fit: contain;
    z-index: 2;

    @media ${device.lg} {

    }

    @media ${device.md} {
        min-width: unset;
        max-width: 150px;
        width: 60%;
        height: 100%;
    }

    @media ${device.sm} {

    }

    @media ${device.xs} {

    }
`


export const Colaboration = () => {

    return (


        <Container>

            <InfoContainer>

                <Info className="utils__fade-in-fast-right">
                    {globalTexts.colab.data}
                </Info>

                <Image
                    className="utils__fade-in-fast-left"
                    src={globalTexts.colab.image}
                    alt="Janos"
                />

            </InfoContainer>

        </Container>

    )
}

export default Colaboration;