import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts } from "../constants/TextsConstants"

const Container = styled.div`
    width: 100%;
    padding: 8vh 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
    z-index: 1;

    @media ${device.md} {
        padding: 5vh 5% !important;
    }   

`

const Title = styled.h2`
    width: 100%;
    font-weight: 500;
    font-size: 52px;
    color: #FF18D3;
    letter-spacing: 3px;
    line-height: 60px;
    text-transform: uppercase;
    text-align: center;

    span {
        font-weight: bolder;
    }

    @media ${device.xl} {
        font-size: 46px;
    }

    @media ${device.lg} {
        font-size: 38px;
        line-height: 50px;
    }

    @media ${device.md} {
        font-size: 32px;
        line-height: 40px;
    }  

    @media ${device.sm} {
        font-size: 24px;
        line-height: 30px;
    }

    @media ${device.xs} {
        font-size: 18px;
        line-height: 25px;
    }
`

export const DoIt = () => {

    return (


        <Container>

            <Title className="utils__fade-in-fast-top">{globalTexts.doit.title}<span>{globalTexts.doit.titleSpan}</span></Title>

        </Container>

    )
}

export default DoIt;