import { types } from "../types/types";


export const setError = ( err ) =>({
    type:types.uiSetError,
    payload: err
});

export const removeError = () =>({
    type:types.uiRemoveError,
});

export const startLoading = () =>({
    type: types.uiStartLoading,
});

export const finishLoading = () =>({
    type: types.uiFinishLoading,
});

export const setPrimaryColor = ( color ) =>({
    type: types.uiSetPrimaryColor,
    payload: color
});

export const startLoadingAsync = () => ({
    type: types.uiStartLoadingAsync,
});

export const finishLoadingAsync = () => ({
    type: types.uiFinishLoadingAsync,
});
