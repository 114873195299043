import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { CardStack } from "./CardStack"
import { globalTexts } from "../constants/TextsConstants"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 1100px;
    margin: 10vh 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ${device.lg} {
        width: 90%;
        flex-direction: column;
    }

    @media ${device.md} {
        margin: 5vh 0;
    }

`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-right: 30px;

    h1 {
        width: 100%;
        font-size: 42px;
        color: #FF18D3;
        letter-spacing: 5px;
        font-weight: 500;
    }

    @media ${device.lg} {
        width: fit-content;
        margin-right: 0px;
        text-align: center;
        margin-bottom: 80px;

        h1 {
            font-size: 32px;
        }

    }

    @media ${device.sm} {
        margin-bottom: 50px;

        h1 {
            font-size: 26px;
        }
    }

    @media ${device.xs} {

        h1 {
            font-size: 20px;
        }
    }
    
`

const InfoContainer = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 20px 0;
    position: relative;
`

export const Reviews = () => {

    return (

        <Container>

            <Target id="reviews" />

            <ContentContainer style={{
                msScrollSnapY: ''
            }}>

                <TitleContainer className="utils__fade-in-fast-right">

                    <h1>{globalTexts.reviews.title}</h1>

                </TitleContainer>

                <InfoContainer className="utils__fade-in-fast-left">

                    <CardStack items={globalTexts.reviews.data} />

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
