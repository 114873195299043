document.addEventListener("DOMContentLoaded", function () {
    scrollFadeInTop();
    scrollFadeInFastTop();

    scrollFadeInBottom()

    scrollFadeInCenter();
    scrollFadeInFastCenter();

    scrollFadeInRight();
    scrollFadeInFastRight();

    scrollFadeInLeft();
    scrollFadeInFastLeft();

    scrollFadeInPreloader();
    scrollFadeInFastPreloader();

});

////////// FADE IN TOP //////////

export function scrollFadeInTop() {

    const faders = document.querySelectorAll('.utils__fade-in-top');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastTop() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-top');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////



////////// FADE IN BOTTOM //////////

export function scrollFadeInBottom() {

    const faders = document.querySelectorAll('.utils__fade-in-bottom');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN CENTER //////////

export function scrollFadeInCenter() {

    const faders = document.querySelectorAll('.utils__fade-in-center');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastCenter() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-center');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN RIGHT //////////

export function scrollFadeInRight() {

    const faders = document.querySelectorAll('.utils__fade-in-right');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastRight() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-right');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN LEFT //////////

export function scrollFadeInLeft() {

    const faders = document.querySelectorAll('.utils__fade-in-left');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastLeft() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-left');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN PRELOADER //////////

export function scrollFadeInPreloader() {

    const faders = document.querySelectorAll('.utils__fade-in-try');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastPreloader() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-try');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////