import { TextsProps } from "../interfaces/TextsInterfaces";

export const endpoints = {
    sendForm: '/v1/completeForm',
    sendEmail: '/v1/sendEmail',
}

export const globalTexts: TextsProps = {
    introduction: {
        title: "Hace que tu evento sea ",
        titleSpan: 'memorable',
        subtitle: "Te vamos a mostrar como..."
    },
    services: {
        title: "Nuestros servicios",
        descriptions: [
            `Nuestra misión es brindar un servicio de excelencia para 
            clientes exigentes, que  buscan alternativas de microexperiencias 
            para sus eventos mas importantes.`,
            `Nos especializamos ofrecer en ellos tatuajes temporales realizados 
            con aerógrafos y tintas hipoalergénicas.`
        ],
        services: [
            {
                title: 'Tattoos',
                subtitle: 'B&W',
                titleColor: '#FFFFFF',
                arrowCircleColor: '#000000',
                mainImage: `${process.env.REACT_APP_CDN}/tattoos.webp`,
                pictures: [
                    `${process.env.REACT_APP_CDN}/tattoos.webp`,
                    `${process.env.REACT_APP_CDN}/tattoos01.webp`, // fluor 
                    `${process.env.REACT_APP_CDN}/tattoos02.webp`,//  black
                    `${process.env.REACT_APP_CDN}/tattoos06.webp`,// fluor 
                    `${process.env.REACT_APP_CDN}/tattoos08.webp`,//  black
                ]
            },
            {
                title: 'Tattoos',
                subtitle: 'Fluor',
                titleColor: '#FF18D3',
                arrowCircleColor: '#FF18D3',
                mainImage: `${process.env.REACT_APP_CDN}/fluor.webp`,
                pictures: [
                    `${process.env.REACT_APP_CDN}/fluor.webp`,
                    `${process.env.REACT_APP_CDN}/tattoos03.webp`,// fluor
                    `${process.env.REACT_APP_CDN}/tattoos04.webp`,// fluor 
                    `${process.env.REACT_APP_CDN}/tattoos05.webp`,// fluor 
                    `${process.env.REACT_APP_CDN}/tattoos07.webp`,// fluor 
                ]
            }
        ],
        aclaration: `Nuestros TATTOOS STATIONS son stands para eventos interactivos. 
        Apto para todas las edades.`
    },
    includes: {
        title: `¿Qué incluye el servicio de stand de tatuajes temporales?`,
        data: [
            `Plantillas de diversos diseños para los tattoos temporales, glitter gemas y strass. 
            Podés incluir maquillaje artístico y stickers tattoo.`,
            `Dos maquilladores profesionales. Puede variar según el tamaño y las necesidades del evento.`,
            `Montaje del stand, luces UV/UV LED, carteles neón y todos los materiales necesarios.`,
            `Tintas hipoalergénicas de color negro, Neón y colores Metalizados.`
        ]
    },
    fyi: [
        `Diversión Segura: Los tatuajes temporales permiten disfrutar del arte corporal sin los 
        riesgos asociados a los tatuajes permanentes..`,
        `Apto para Todos: Nuestras tintas son hipoalergénicas y aprobadas por ANMAT, lo que las hace 
        seguras para todo tipo de piel.`,
        `Personalización: Los clientes pueden elegir entre una amplia variedad de diseños y colores, 
        incluyendo opciones neon y glitter.`,
    ],
    colab: {
        data: `Nos enorgullece mencionar nuestra colaboración con Jano's, una empresa con más de 50 salones 
        en todo Buenos Aires, a los cuales ya hemos brindado más de 300 servicios de manera ininterrumpida.`,
        image: `${process.env.REACT_APP_CDN}/janos.webp`
    },
    reviews: {
        title: 'No te pierdas lo que dicen nuestros clientes',
        data: [
            {
                name: "Laura M.",
                designation: "",
                content: (
                    <p>
                        “La atención fué excelente y los diseños, <span>espectaculares</span>.
                        Todos los invitados quedaron impresionados. <span>¡Muy recomendable!</span>”
                    </p>
                ),
            },

            {
                name: "Ana L.",
                designation: "",
                content: (
                    <p>
                        “Muy contenta con el servicio. Los tatuajes temporales fueron
                        un gran atractivo y los niños se divirtieron mucho.”
                    </p>
                ),
            },
            {
                name: "Julia D.",
                designation: "",
                content: (
                    <p>
                        “<span>Profesionales</span> y <span>puntuales</span>. Los tatuajes
                        fueron un gran <span>éxito</span> entre los niños y adultos. ¡Gracias por todo!”
                    </p>
                ),
            },
        ]
    },
    doit: {
        title: 'No esperes más, hace tu evento ',
        titleSpan: 'UNICO'
    },
    form: {
        title: 'Contactate con nosotros',
        titleForm: '¡Gracias por tu interés en Glowink BA!',
        subtitleForm: `Te vamos a pedir algunos datos para poder armar tu presupuesto 
        personalizado y acorde a tu fiesta.`,
        services: [
            { id: '1', value: 'Stand Tattoo Neón' },
            { id: '2', value: 'Stand Tattoo Black' },
            { id: '4', value: 'Stand Maquillaje Glitter' },
        ]
    },
    social: [
        {
            image: `${process.env.REACT_APP_CDN}/ig.webp`,
            link: 'https://www.instagram.com/glowink.ba/',
            event: 'click_boton_instagram'
        },
        {
            image: `${process.env.REACT_APP_CDN}/facebook.webp`,
            link: 'https://www.facebook.com/people/Glowinkba/100091554009990/',
            event: 'click_boton_facebook'
        },
        {
            image: `${process.env.REACT_APP_CDN}/tiktok.webp`,
            link: 'https://www.tiktok.com/@glowink.ba',
            event: 'click_boton_tiktok'
        }
    ]
}