import { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
`

const ContentContainer = styled.div`
    height: 100%;
    width: 90vw;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 2rem 3rem;
    
    @media ${device.sm} {
        width: 100vw;
    } 

`

const Image = styled.img`
    width: 100%;
    max-height: 75vh;
    object-fit: contain;
`

const Close = styled.img`
    width: 20px;
    height: 20px;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 100;
`

const ArrowContainer = styled.div`
    position: fixed;
    right: 3rem;
    top: 0px;
    bottom: 0px;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    //

    position: absolute;
    right: 0px;
    left: 0px;
    top: 10vh;
    bottom: 10vh;
    height: fit-content;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

`

const ArrowLeft = styled.div`
    position: relative;
    cursor: pointer;

    span {
        display: block;
        width: 1rem;
        height: 1rem;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
        animation: animateGoLeft 3s ease-in-out infinite;

    }

`

const ArrowRight = styled.div`
    position: relative;
    cursor: pointer;

    span {
        display: block;
        width: 1rem;
        height: 1rem;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
        animation: animateGoRight 3s ease-in-out infinite;


    }

`

const Counter = styled.div`
    position: absolute;
    bottom: 10vh;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & > div {
        width: 10px;
        height: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-left: 7.5px;
        margin-right: 7.5px;
        border-radius: 10rem;
        z-index: 1;
    }

    @media ${device.sm} {
        bottom: 5vh;
    } 


`

interface Props {
    showModal: string[] | null
    setShowModal: React.Dispatch<React.SetStateAction<string[] | null>>
}

export const ServiceImagesModalComponent = ({ showModal, setShowModal }: Props) => {

    const containerRef = useRef(null);

    const [visibleImage, setVisibleImage] = useState(0);

    const handleEscKeyPress = (event: any) => {
        if (event.key === 'Escape') {
            setShowModal(null)
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscKeyPress);
        return () => {
            window.removeEventListener('keydown', handleEscKeyPress);
        };
    }, []);
    if (showModal === null)
        return <></>;

    const cambiarImagen = (direction: any) => {

        const totalImagenes = showModal.length;

        let nuevaImagenVisible = visibleImage + direction;

        if (nuevaImagenVisible === -1) {
            nuevaImagenVisible = totalImagenes - 1;
        } else if (nuevaImagenVisible < 1) {
            nuevaImagenVisible = 0;
        } else if (nuevaImagenVisible >= totalImagenes) {
            nuevaImagenVisible = 0;
        }

        setVisibleImage(nuevaImagenVisible);
    };

    return (

        <Container id="modal">

            <button
                onClick={() => {
                    setShowModal(null)
                }}
            >

                <Close
                    className='workModal__close-image'
                    src={`${process.env.REACT_APP_CDN}/cerrar.webp`}
                    alt='logo'
                    id="cerrarModal"
                />

            </button>

            <ArrowContainer>

                <ArrowLeft onClick={() => cambiarImagen(-1)}>
                    <span></span>
                </ArrowLeft>

                <ArrowRight onClick={() => cambiarImagen(1)}>
                    <span></span>
                </ArrowRight>

            </ArrowContainer>


            <Counter>

                {
                    showModal.map((value, index) => (

                        <div
                            style={{ backgroundColor: index === visibleImage ? 'white' : undefined }}
                            key={value}
                        />

                    ))
                }

            </Counter>


            <ContentContainer ref={containerRef}>

                <Image
                    src={showModal[visibleImage]}
                    alt='imagen'
                />

            </ContentContainer>

        </Container>

    )
}

const ServiceImagesModal = memo(ServiceImagesModalComponent);

export default ServiceImagesModal;