import { css, styled } from "styled-components";
import { CSSProperties } from "react";
import Switch from 'react-switch';
import { Icon } from "./atoms/Icon";
import { Typography } from "./atoms/Typography";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border-radius: 6px;

    & > h1 {
        white-space: normal;
        text-align: start;
        margin-left: 10px;
        width: 100%;
    }
    
    & > img {
        margin-left: 15px;
    }

    `



const CheckboxInput = styled.input`
    white-space: 'normal';
    font-size: 18px;
    cursor: pointer;
`

interface Props {
    type: 'checkbox' | 'switch';
    icon?: string
    text?: string
    checked: boolean
    disabled?: boolean
    fullWidth?: boolean
    style?: CSSProperties
    textColor?: string
    onClick: () => void
}

export const Checkbox = ({ type, text, icon, checked, disabled, fullWidth, style, textColor, onClick }: Props) => {


    return (

        <CheckboxContainer style={{ ...style }}>

            {
                type === "checkbox" ? (

                    <CheckboxInput
                        type="checkbox"
                        id={text}
                        name={text}
                        disabled={disabled}
                        checked={checked}
                        style={{
                            width: fullWidth ? '100%' : 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onChange={() => onClick()}
                    >

                    </CheckboxInput>

                ) : (

                    <Switch onChange={onClick} checked={checked} />

                )
            }

            {icon ? (
                <Icon
                    size="M"
                    src={icon}
                />
            ) : null
            }

            {text ? (
                <Typography
                    size={type === "checkbox" ? "12px" : "16px"}
                    color={textColor}
                >
                    {text}
                </Typography>
            ) : null
            }
        </CheckboxContainer>

    )
}
