import { CSSProperties, useState } from "react"
import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { showMixinToast, showModalMessage } from "../utils/Alerts"
import axios from 'axios'
import { FormProps } from "../interfaces/ContactInterfaces"
import { Checkbox } from "./Checkbox"
import { endpoints, globalTexts } from '../constants/TextsConstants';
import Social from "./Social"
import { ServiceFormProps } from "../interfaces/TextsInterfaces"
import { useDispatch } from "react-redux"
import { finishLoadingAsync, startLoadingAsync } from "../actions/ui"

const Container = styled.div`
    width: 100%;
    padding: 10vh 0px 0px 0px;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    max-width: 500px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

`

const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 100px;

    h1 {
        font-size: 34px;
        color: #FFF;
        text-align: center;
        line-height: 50px;
    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        margin: auto;
        width: 50px;
        height: 3px;
        border-radius: 50px;
        background-color: #FF18D3;
    }

    
    @media ${device.md} {

        h1 {
            font-size: 32px;
        }

        h1::before {
            top: 65px;
        }

    }

    @media ${device.sm} {
        margin-bottom: 80px;

        h1 {
            font-size: 26px;
        }

        h1::before {
            top: 55px;
        }
    }

    @media ${device.xs} {

        h1 {
            font-size: 20px;
        }

        h1::before {
            top: 50px;
            width: 40px;
        }
    }


`

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 50px 50px;
    border-radius: 15px;
    background-color: rgba(87, 87, 87, 0.1);
    border: 2px solid #FF18D3;
    box-shadow: 0px 0px 12px 0px rgba(255, 24, 211, 0.9);

    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        
        & > h2 {
            
            color: #fff;
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: 600;
            text-align: center;

        }

        & > p {
            color: #ffffff;
            margin-bottom: 10px;
            font-size: 14px;
            text-align: center;
        }

        @media ${device.sm} {
            & > h2 {
                font-size: 14px;

            }

            & > p {
                font-size: 12px;
            }
        }
        
    }

    @media ${device.md} {
        padding: 50px 50px;
    }

    @media ${device.sm} {
        width: 80%;
        padding: 30px 30px;
    }

    @media ${device.xs} {
        padding: 30px 20px;
    }
`

const InputContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    position: relative;

`

const CheckboxContainer = styled.div<{ incomplete?: string }>`
    width: 100%;
    margin-top: 50px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 15px;
    gap: 20px;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: all 0.5s ease-in-out;


    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        padding: 10px;
        gap: 15px;
    }

`

const Input = styled.input<{ incomplete?: string }>`
    width: 100%;
    max-width: 404px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    box-shadow: none;
    height: 42px !important;
    padding-left: 20px;
    font-size: 14px;
    background-color: transparent;
    color: #fff;

    @media ${device.sm} {
        font-size: 12px;
        max-width: 336px;
        height: 40px !important;
        border-radius: 6px;
    }

    @media ${device.xs} {
        max-width: 276px;
    }

    ${({ incomplete }) => incomplete === 'true' ? css`
        border-color: #ff7071;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `: css`
        &:focus {
            outline: 0 none;
            border-color: #4baf51;
        }
    `}


    label {
        color: #2c3357;
        font-size: 13px;
        font-weight: 400;
        left: 5px;
        position: absolute;
        top: -25px;
    }
`

const Label = styled.label<{ required?: boolean }>`
    color: #e1e1e1;
    font-size: 13px;
    font-weight: 400;
    left: 5px;
    position: absolute;
    top: -25px;

    white-space: nowrap;
    text-overflow: ellipsis;

    @media ${device.sm} {
        font-size: 12px;
        top: -20px;
    }

    ${props => props.required && css`

        &::after {
            content: '*';
            color: #f52d2d;
            font-size: 18px;
            position: absolute;
            right: -12px;
            top: -5px;
        }
    `}

`

const InputDescription = styled.p`
    color: #e1e1e1;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
    margin-left: 5px;

    @media ${device.sm} {
        font-size: 11px;
        margin-top: 3px;
        margin-left: 3px;
    }
`


const SendButton = styled.button`
    position: relative;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #4baf51;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 15px 30px;
    border-radius: 50px;
    margin-top: 50px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.2);
    }

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.2);
        background-color: #3d9843;

        &::before {
            animation: fillButton 0.5s ease forwards;
        }
    }

    @media ${device.sm} {
        font-size: 12px;
        padding: 15px 25px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`

export const ContactForm = () => {

    const { handleLogEvent } = useLogEvent();

    const dispatch = useDispatch();

    const [form, setForm] = useState<FormProps>({
        contact: '',
        services: [],
        amount: '',
        location: '',
        date: '',
        time: '',
    })


    const [fieldIncomplete, setFieldIncomplete] = useState({
        contact: false,
        services: false,
        amount: false,
        date: false,
        time: false,
    })

    const formValidations = () => {

        let existError: number = 0;

        let msg = ''

        setFieldIncomplete({
            contact: form.contact.trim().length === 0,
            services: form.services.length === 0,
            amount: form.amount.trim().length === 0,
            date: form.date.length < 10 || form.date.trim().split("/", 3).length != 3,
            time: (form.time.length > 0 && form.time.length < 5) || (form.time.length > 5) || (form.time.length === 5 && form.time.trim().split(":", 2).length != 2),
        });

        if (form.contact.trim().length === 0) {
            existError++;
            msg = "Indique una forma de contacto."
        }
        if (form.services.length === 0) {
            existError++;
            msg = "Seleccione al menos un servicio."
        }
        if (form.amount.trim().length === 0) {
            existError++;
            msg = "Indique la cantidad de invitados."
        }
        if (form.date.length < 10 || form.date.trim().split("/", 3).length != 3) {
            existError++;
            msg = "Ingrese una fecha correcta."
        }
        if ((form.time.length > 0 && form.time.length < 5) || (form.time.length > 5) || (form.time.length === 5 && form.time.trim().split(":", 2).length != 2)) {
            existError++;
            msg = "Ingrese un horario correcto o deje el campo vacío."
        }

        existError > 0 && showModalMessage('Atención', existError > 1 ? 'Verifique la información ingresada' : msg, 'warning')

        return existError;

    }



    const handleFormConfirm = async (e: any) => {

        e.preventDefault();

        if (formValidations() === 0) {

            dispatch(startLoadingAsync());

            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendForm}`, form).then(() => {

                    dispatch(finishLoadingAsync());

                    handleLogEvent('click_boton_enviar_formulario');

                    showMixinToast('Formulario enviado', 'success');

                    setForm({
                        contact: '',
                        services: [],
                        amount: '',
                        location: '',
                        date: '',
                        time: '',
                    })

                    setFieldIncomplete({
                        contact: false,
                        services: false,
                        amount: false,
                        date: false,
                        time: false,
                    })

                }).catch(async (e) => {

                    dispatch(finishLoadingAsync());

                    showMixinToast('Ocurrio un error', 'error');

                    axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_COMMON}${endpoints.sendEmail}`, {
                        from: 'ERROR FORM GLOWINK',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { msg: e, error: JSON.stringify(e, null, 2) }
                    })


                })

            dispatch(finishLoadingAsync());
        }
    }

    const styleCompleted: CSSProperties = {
        outline: '0 none',
        borderColor: '#4baf51',
    }

    const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        // Eliminar cualquier carácter no numérico
        value = value.replace(/\D/g, '');

        if (/^\d*$/.test(value)) {

            // Si el valor es mayor a 2 dígitos, insertamos ':'
            if (value.length > 2) {
                value = value.slice(0, 2) + ':' + value.slice(2, 4);
            }

            // Limitar a un formato HH:MM
            if (value.length > 5) {
                value = value.slice(0, 5);
            }

            // Validar horas y minutos
            const [hours, minutes] = value.split(':');
            if (hours && parseInt(hours) > 23) {
                value = '23:' + (minutes || '');
            }
            if (minutes && parseInt(minutes) > 59) {
                value = (hours || '00') + ':59';
            }

            setForm({ ...form, time: value });

        }
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        // Eliminar cualquier carácter no numérico
        value = value.replace(/\D/g, '');

        if (/^\d*$/.test(value)) {

            setFieldIncomplete({ ...fieldIncomplete, date: false })


            // Insertar '/' después de 2 dígitos para el día y el mes
            if (value.length > 2) {
                value = value.slice(0, 2) + '/' + value.slice(2);
            }
            if (value.length > 5) {
                value = value.slice(0, 5) + '/' + value.slice(5, 9);
            }

            // Limitar la longitud total a 10 caracteres (DD/MM/YYYY)
            if (value.length > 10) {
                value = value.slice(0, 10);
            }

            const [day, month, year] = value.split('/').map(Number);

            // Validación del día y mes
            if (day && (day < 1 || day > 31)) {
                value = '31' + (month ? '/' + month.toString().padStart(2, '0') : '') + (year ? '/' + year : '');
            }
            if (month && (month < 1 || month > 12)) {
                value = (day ? day.toString().padStart(2, '0') : '') + '/12' + (year ? '/' + year : '');
            }

            setForm({ ...form, date: value });

        }
    };

    return (

        <Container>

            <Target id="contact" />

            <ContentContainer>

                <TitleDiv className="utils__fade-in-fast-center">

                    <h1>{globalTexts.form.title}</h1>

                </TitleDiv>

                <FormContainer >

                    <div>

                        <h2 className="utils__fade-in-fast-right">{globalTexts.form.titleForm}</h2>

                        <p className="utils__fade-in-fast-left">{globalTexts.form.subtitleForm}</p>

                        <InputContainer className="utils__fade-in-fast-top">

                            <Label required>¿A dónde te contactamos?</Label>

                            <Input
                                type={"text"}
                                placeholder={'Medio de contacto'}
                                value={form.contact}
                                incomplete={fieldIncomplete.contact.toString()}

                                onChange={(e: any) => {

                                    setFieldIncomplete({ ...fieldIncomplete, contact: false })
                                    setForm({
                                        ...form,
                                        contact: e.target.value
                                    })
                                }}
                                style={(form.contact.trim() !== '' && !fieldIncomplete.contact) ? { ...styleCompleted } : {}}
                            ></Input>

                            <InputDescription>Dejanos tu WhatsApp</InputDescription>

                        </InputContainer>

                        <CheckboxContainer incomplete={fieldIncomplete.services.toString()} className="uutils__fade-in-fast-top">

                            <Label required>Seleccione los servicios buscados</Label>
                            {
                                globalTexts.form.services.map((item: ServiceFormProps) => {

                                    return (

                                        <Checkbox
                                            type="checkbox"
                                            key={item.id}
                                            text={item.value}
                                            textColor="#e1e1e1"
                                            checked={form.services.includes(item.id)}

                                            onClick={() => {

                                                setFieldIncomplete({ ...fieldIncomplete, services: false })

                                                if (form.services.includes(item.id)) {
                                                    setForm({
                                                        ...form,
                                                        services: form.services.filter((value) => { return value !== item.id })
                                                    })
                                                } else {
                                                    setForm({
                                                        ...form,
                                                        services: [...form.services, item.id]
                                                    })
                                                }


                                            }}
                                            style={{ width: 'fit-content', alignSelf: 'start' }}
                                        />

                                    )
                                })
                            }
                        </CheckboxContainer>

                        <InputContainer className="utils__fade-in-fast-top">

                            <Label required>Cantidad de invitados</Label>

                            <Input
                                type={"text"}
                                placeholder={'Cantidad aproximada'}
                                value={form.amount}
                                incomplete={fieldIncomplete.amount.toString()}
                                onChange={(e: any) => {

                                    let value = e.target.value;

                                    // Eliminar cualquier carácter no numérico
                                    value = value.replace(/\D/g, '');

                                    if (/^\d*$/.test(value)) {

                                        setFieldIncomplete({ ...fieldIncomplete, amount: false })
                                        setForm({
                                            ...form,
                                            amount: value
                                        })

                                    }

                                }}
                                style={(form.amount.trim() !== '' && !fieldIncomplete.amount) ? { ...styleCompleted } : {}}
                            />

                        </InputContainer>


                        <InputContainer className="utils__fade-in-fast-top">

                            <Label>Locación del evento</Label>

                            <Input
                                type={"text"}
                                placeholder={'Ubicación'}
                                value={form.location}
                                onChange={(e: any) => {
                                    setForm({
                                        ...form,
                                        location: e.target.value
                                    })
                                }}
                                style={form.location.trim() !== '' ? { ...styleCompleted } : {}}
                            />

                            <InputDescription>(Salón y localidad)</InputDescription>

                        </InputContainer>

                        <InputContainer className="utils__fade-in-fast-top">

                            <Label required>Día del evento</Label>

                            <Input
                                type={"text"}
                                placeholder={'DD/MM/YYYY'}
                                value={form.date}
                                incomplete={fieldIncomplete.date.toString()}
                                maxLength={10}
                                onChange={handleDateChange}
                                style={(form.date.trim() !== '' && !fieldIncomplete.date) ? { ...styleCompleted } : {}}
                            />

                        </InputContainer>

                        <InputContainer className="utils__fade-in-fast-top">

                            <Label>Horario de preferencia</Label>

                            <Input
                                type={"text"}
                                placeholder={'HH:MM'}
                                value={form.time}
                                maxLength={5}
                                incomplete={fieldIncomplete.time.toString()}
                                onChange={handleTimeChange}
                                style={(form.time.trim() !== '' && !fieldIncomplete.time) ? { ...styleCompleted } : {}}
                            />

                        </InputContainer>

                    </div>

                    <SendButton
                        className="utils__fade-in-fast-top"
                        onClick={(e) => handleFormConfirm(e)}
                    >
                        Enviar forulario
                    </SendButton>

                </FormContainer>

                <Social />

            </ContentContainer>


        </Container>

    )
}
